* {
  font-family: "Montserrat", sans-serif;
}

.border-rounded {
  border-radius: 15px !important;
}

.border-rounded--bottom {
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.pointer {
  cursor: pointer;
}

/* card animation */
@keyframes card-hover-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}

.mycard {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.mycard:hover {
  z-index: 2;
  transform: scale(1.04);
}

.mycard:hover .card-title {
  width: auto !important;
}

/* Loading animation */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: red;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.sdds {
  background-color: #c4313d;
}

body {
  background-color: #f1faee;
}

.bar {
  /* width: 100%; */
  border-radius: 0px !important;
  border-bottom: 2px solid red;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  z-index: 1000;
}

.padding120 {
  padding-top: 120px;
}

/* for scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*  pagination  */

.pagination .page-link {
  color: #de4150 !important;
  border: #de4150 !important;
}

.pagination .active .page-link {
  background-color: #de4150 !important;
  color: white !important;
  border: #de4150 !important;
}
